<template>
  <div class="my-zupus-landing">
    <div class="info_page">
      <header>
        <h1>Search. Save. Translate.</h1>
        <description-disclaimer></description-disclaimer>
        <mcr-buttons-row>
          <mcr-button-router-link
            :to="toRegister"
            :label="registerLabel"
            @click="trackPreRegisterAction"
          ></mcr-button-router-link>
        </mcr-buttons-row>
        <p class="login-link">Existing user? <router-link :to="toLogin">Log in</router-link></p>
        <img :src="$getAsset('/assets/services/zupus/dict-preview.png')" class="header-splash" />
        <frequently-asked-questions></frequently-asked-questions>
      </header>
    </div>
    <mcr-footer></mcr-footer>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import mcrFooter from '@/components/common/mcrFooter';

import DescriptionDisclaimer from '@/components/modules/myZupus/common/DescriptionDisclaimer';
import FrequentlyAskedQuestions from '@/components/modules/myZupus/common/FrequentlyAskedQuestions';

export default {
  metaInfo: {
    title: 'My Zupus',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Preserve, organize and decode your family records from China thanks to our built-in Chinese-English dictionary.',
      },
    ],
  },
  data() {
    return {
      registerLabel: 'Get Started',
    };
  },
  computed: {
    toRegister() {
      return {name: 'register', query: {redirect: this.$route.fullPath}};
    },
    toLogin() {
      return {name: 'login', query: {redirect: this.$route.fullPath}};
    },
  },
  methods: {
    trackPreRegisterAction() {
      AnalyticsAmplitudeHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
  },
  components: {DescriptionDisclaimer, FrequentlyAskedQuestions, McrButtonRouterLink, mcrFooter, mcrButtonsRow},
};
</script>

<style lang="scss" scoped>
@import '@common/style/info.page';

.header-splash {
  padding-bottom: 36px;
  border-bottom: 1px solid $divider-line-light;
}
</style>
